
import Navigation from "./partials/navigation/Navigation";
import AppFooter from "./partials/footer/Footer";
import AuthManager from "~/fc/Auth/AuthManager";
import { updateFreshDeskPageData } from "~/fc/FreshDesk";
import FreshdeskWidgetLoader from "~/components/ui/FreshdeskWidgetLoader";
import { initiateAds, resetAds } from "~/fc/Ads";

export default {
  name: "DefaultLayout",
  components: {
    Navigation,
    AppFooter,
    FreshdeskWidgetLoader,
    BirdEatsBugTrigger: () =>
      import(
        /* webpackChunkName: "BirdEatsBugTrigger" */ "~/components/ui/BirdEatsBugTrigger"
      ),
    ToastContainer: () =>
      import(
        /* webpackChunkName: "ToastContainer" */ "~/components/ui/ToastContainer"
      ),
    ApplinksPopup: () =>
      import(
        /* webpackChunkName: "ApplinksPopup" */ "~/components/ApplinksPopup"
      ),
  },
  data: () => ({
    showLoader: false,
    showFullPage: false,
  }),
  computed: {
    isMobile() {
      return this.$store.state.store.isMobile;
    },
    authManager() {
      return new AuthManager(this);
    },
    showBreadcrumb() {
      return !["pricing", "auth", "checkout", "index"].some((v) => {
        return this.$route.name ? this.$route.name.includes(v) : false;
      });
    },
    info() {
      return this.$store.state.info;
    },
    list() {
      return this.$store.state.list;
    },
    hasFiles() {
      return !!this.$store.state.list.length;
    },
    fileType() {
      return this.info.type
        ? this.info.type
        : this.info.target && this.info.target.group
        ? this.info.target.group.toLowerCase()
        : "";
    },
    enablePreview() {
      return (
        this.info.active === "PreviewTemplate" ||
        (this.info.customVariables
          ? this.info.customVariables.enablePreview
          : false)
      );
    },
    showPageFooter() {
      return !(
        this.enablePreview &&
        this.hasFiles &&
        !this.$route.path.includes("download")
      );
    },
  },
  watch: {
    $route(newRoute, oldRoute) {
      this.$nextTick(() => {
        this.handleRouteChange(newRoute, oldRoute);
      });
      if (!newRoute.path.includes("download"))
        this.$store.commit("setList", []);
    },
  },
  created() {
    if (process.client) {
      this.$store.dispatch("getLocalSubscription");
    }
  },
  mounted() {
    this.initiateApp();
  },
  methods: {
    initiateApp() {
      this.preFetchSupportedTargets();
      this.initiateSubscription();
      this.initiateAuthToken();
      this.initiateResponsive();
      this.checkIfFromCampaign();
      this.showLoader = false;
      initiateAds();
      this.$store.commit("setInteractionInitiated", true);
      setTimeout(() => {
        this.$store.commit("setDocumentMounted", true);
      }, 2000);
    },
    preFetchSupportedTargets() {
      this.$store.dispatch("advancedSetting/assignSupportedTargets");
    },
    openFreshDesk() {
      this.$bus.$emit("OPEN_FW");
    },
    showConsoleSecurityWarning() {
      const warningTitleCSS =
        "color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;";
      const warningDescCSS = "font-size: 1.125em;";
      console.log("%cStop!", warningTitleCSS);
      console.log(
        "%cThis is a browser feature intended for developers. If someone told you to copy and paste something here, it is a scam and will give them access to your account.",
        warningDescCSS
      );
    },
    initiateAuthToken() {
      this.authManager.checkExpiryOfToken();
    },
    initiateSubscription() {
      this.$store.dispatch("getSubscription", this.$auth?.token);
    },
    initiateResponsive() {
      this.$nextTick(() => {
        if (!this.isMobile) {
          if (window.innerWidth <= 768)
            this.$store.commit("store/updateIsMobile", true);
          // eslint-disable-line curly
          else this.$store.commit("store/updateIsMobile", false); // eslint-disable-line curly
        }
      });
    },
    handleRouteChange(newRoute, oldRoute) {
      this.$bus.$emit("ROUTE_CHANGE", newRoute);
      this.count++;
      resetAds();
      this.initiateAuthToken();
      updateFreshDeskPageData(this);
      this.$store.commit("setFileInputConcurrentLength", 0);
    },

    checkIfFromCampaign() {
      const telemetryUserTimeStamp = localStorage.getItem("TelemetryUser");
      const dateNow = new Date();
      const dateExpiry = new Date(parseInt(telemetryUserTimeStamp));
      if (dateExpiry >= dateNow)
        this.$store.commit("store/setTelemetryUser", true);
    },
  },
};
